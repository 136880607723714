import React from 'react'
import Layout from '../containers/Layout'
import training from '../config/text/training.json'

class Training extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/frothing-coffee-barista-training.jpg' heroText={training.title} title={training.meta.title} description={training.meta.description}>
        <div className='training container'>
          <div className='row'>
            <div className='col-xs-12'>
              {training.description.map(t => <p>{t}</p>)}
            </div>
          </div>
          <div className='row'>
            <div className='col-md mb-3 p-0'>
              <h4>Essential Barista Skills and Knowledge</h4>
              {createList(training.points2)}
            </div>
            <div className='col-md mb-3 p-0'>
              <h4>Range of Coffees</h4>
              {createList(training.points)}
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              Our preference is to train you at your premises on your machine. After all, that is the machine you will be using.
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const createList = points => {
  return (
    <div>
      {points.map(p => <div className='pb-1'>{p}</div>)}
    </div>
  )
  // return (
  //   <ul>
  //     {points.map(pnt => {
  //         return <li>{pnt}</li>
  //     })}
  //   </ul>
  // )
}

export default Training
